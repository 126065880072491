import React from 'react'
import '../../src/blurb.css'
import Typing from 'react-typing-animation'
// import Cursor from './Cursor';

const content = <span><span class="var-highlight">const</span>
<span class="const-highlight">{' about_me '}</span>
<span class="white-highlight">{'='}</span>
<span class="bracket-highlight">{' {'}</span>
<br />
<span class="key-highlight">{'    loves: '}</span>
<span class="string-highlight">{'"CyberSec, Creating Stuff"'}</span>
<span class="white-highlight">{',\n'}</span>
<span class="key-highlight">{'    currently_reading: '}</span>
<span class="string-highlight">{'"Gandhi: The Years That Changed \nthe World, 1914-1948"'}</span>
<span class="white-highlight">{',\n'}</span>
<span class="key-highlight">{'    other_interests: '}</span>
<span class="array-highlight">{'[\n'}</span>
<span class="string-highlight">{'        "Reading 📚"'}</span>
<span class="white-highlight">{',\n'}</span>
<span class="string-highlight">{'        "IoT Projects 💡"'}</span>
<span class="white-highlight">{',\n'}</span>
<span class="string-highlight">
  {'        "Running 🏃( Aim to run a full marathon )"'}
</span>
<span class="white-highlight">{',\n'}</span>
<span class="string-highlight">{'        "Swimming 🏊‍♂️"\n'}</span>
<span class="array-highlight">{'    ]'}</span>
<span class="white-highlight">{',\n'}</span>
<span class="key-highlight">{'    online_presence: '}</span>
<span class="string-highlight">
  <a
    href="https://www.youtube.com/channel/UCARsgS1stRbRgh99E63Q3ng"
    class="fancy-link"
  >
    <svg
      class="[ icon ] [ fancy-link__icon ]"
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="youtube"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
    >
      <path
        fill="red"
        d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
        class="[ icon ] [ fancy-link__icon ]"
      ></path>
    </svg>
    HackingSimplified
  </a>
</span>
<span>&nbsp;</span>
<span class="string-highlight">
  <a
    href="https://twitter.com/AseemShrey?ref_src=twsrc%5Etfw"
    class="fancy-link"
  >
    <svg
      class="[ icon ] [ fancy-link__icon ]"
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="twitter"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="#1DA1F2"
        d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
        class="[ icon ] [ fancy-link__icon ]"
      ></path>
    </svg>
    AseemShrey
  </a>
</span>
<span class="bracket-highlight">{'\n}'}</span>
<span class="white-highlight">{';\n'}</span>
<br /></span>;

export default function Intro({ notyping }) {
  if(notyping){
    return(
      <pre id="typewriter">
        {content}
        <span id="typewriter_cursor" >
          {'Ping me up if you wanna talk about anything.'}
        </span>
      </pre>
    );
  }
  else return (
      <pre id="typewriter">
      {content}
      <Typing speed={20}>
        <span id="typewriter_cursor" >
          {'Ping me up if you wanna talk about anything.'}
        </span>
      </Typing>
      </pre>
  )
}
